<template>
  <div class="profile-modal-view" v-if="!isEditing">
    <button class="back-button" @click="$emit('back')">Back</button>
    <div class="content">
      <h2 class="name">{{ buyer.name }}</h2>
      <div class="info">
        <div class="info-item" v-for="(item, index) in infoItems" :key="index">
          <div class="label-container">
            <span class="label">{{ item.label }}</span>
            <span class="edit-icon" @click="openEditModal(item.key, item.label, item.value)">&nbsp;✎</span>
          </div>
          <span class="value">{{ item.value }}</span>
        </div>
      </div>
      <a href="#" class="ban-button" @click.prevent="banUser">Ban user</a>
    </div>
  </div>

  <EditModal
    v-if="isEditing"
    :title="modalTitle"
    :initialValue="modalInitialValue"
    @close="closeEditModal"
    @confirm="confirmEdit"
  />
</template>

<script>
import EditModal from './EditModal.vue';

export default {
  components: {
    EditModal,
  },
  props: {
    buyer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      modalTitle: '',
      modalInitialValue: '',
      editKey: '',
    };
  },
  computed: {
    infoItems() {
      return [
        { label: 'Email', value: this.buyer.email, key: 'email' },
        { label: 'Password', value: this.buyer.password, key: 'password' },
        { label: 'Number', value: this.buyer.phone, key: 'phone' },
        { label: 'Date Joined', value: this.buyer.created_at, key: 'created_at' },
        { label: 'Account Type', value: this.buyer.account_type, key: 'account_type' },
      ];
    },
  },
  methods: {
    banUser() {
      alert('User banned'); 
    },
    openEditModal(key, label, value) {
      this.modalTitle = `Edit ${label}`;
      this.modalInitialValue = value;
      this.editKey = key;
      this.isEditing = true;
    },
    closeEditModal() {
      this.isEditing = false;
    },
    confirmEdit(newValue) {
      this.$emit('update-buyer', { key: this.editKey, value: newValue });
      this.closeEditModal();
    },
  },
};
</script>

<style scoped>
.profile-modal-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: white;
  border-radius: 10px;
  text-align: center;
  position: relative;
}

.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  color: #000;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  color: #000;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.info {
  margin-bottom: 20px;
  width: 100%; 
}

.info-item {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  position: relative;
}

.label-container {
  display: flex;
  align-items: center;
  justify-content: center; 
}

.label {
  font-weight: bold;
  display: block;
  margin-right: 5px; 
}

.value {
  color: #666;
}

.edit-icon {
  display: none;
  cursor: pointer;
  color: #888;
}

.info-item:hover .edit-icon {
  display: inline;
}

.ban-button {
  color: #ff4444;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
}
</style>
