<template>
    <div class="profile-view">
      <button class="close-button" @click="$emit('close')">✕</button>
      <div class="content">
        <img :src="buyer.profileImage || defaultImage" alt="Profile Image" class="profile-image" />
        <h2 class="name">{{ buyer.name }}</h2>
        <div v-if="buyer.isTopSeller" class="badge">Top Seller</div>
        <div class="info">
          <div class="info-item">
            <span class="label">Email</span>
            <span class="value">{{ buyer.email }}</span>
          </div>
          <div class="info-item">
            <span class="label">Number</span>
            <span class="value">{{ buyer.phone }}</span>
          </div>
        </div>
        <a href="#" class="detailed-view" @click.prevent="$emit('detailed-view')">Detailed View</a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      buyer: {
        type: Object,
        required: true,
      },
    },
    computed: {
      defaultImage() {
        return '/assets/images/p.jpeg';
      },
    },
  };
  </script>
  
  <style scoped>
  .profile-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: white;
    border-radius: 10px;
    text-align: center;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
    color: #000;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover;
  }
  
  .name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .badge {
    background-color: #37b883;
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    margin-bottom: 20px;
    display: inline-block;
  }
  
  .info {
    margin-bottom: 20px;
  }
  
  .info-item {
    margin-bottom: 10px;
  }
  
  .label {
    font-weight: bold;
    display: block;
  }
  
  .value {
    color: #666;
  }
  
  .detailed-view {
    color: #37b883;
    text-decoration: underline;
    cursor: pointer;
  }
  </style>
  