<template>
    <div class="edit-modal">
      <button class="close-button" @click="$emit('close')">✕</button>
      <h2>{{ title }}</h2>
      <input v-model="inputValue" :placeholder="placeholder" @input="checkIfEdited" />
      <button :class="['confirm-button', { edited: isEdited }]" @click="confirmEdit">Confirm</button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      initialValue: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        inputValue: this.initialValue,
        isEdited: false,
      };
    },
    methods: {
      checkIfEdited() {
        this.isEdited = this.inputValue !== this.initialValue;
      },
      confirmEdit() {
        this.$emit('confirm', this.inputValue);
      },
    },
  };
  </script>
  
  <style scoped>
  .edit-modal {
    position: relative;
    padding: 20px;
    background: white;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
    color: #000;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  input {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 300px;
  }
  
  .confirm-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #ccc;
    color: white;
    cursor: pointer;
  }
  
  .confirm-button.edited {
    background-color: #37b883;
  }
  </style>
  