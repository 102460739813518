<template>
  <div>
    <!-- Form for updating category -->
    <div class="flex flex-col items-center">
      <!-- Category Icon -->
      <div class="w-24 h-24 bg-gray-200 rounded-full flex justify-center items-center mb-4 relative">
        <img v-if="previewImage" :src="previewImage" alt="category icon" class="absolute inset-0 w-full h-full object-cover rounded-full">
        <input type="file" @change="onFileChange" class="hidden" ref="fileInput">
        <button class="text-gray-400 text-4xl" @click="triggerFileInput" v-if="!previewImage">+</button>
        <button class="absolute inset-0 w-full h-full" @click="triggerFileInput" v-if="previewImage"></button>
      </div>
      <!-- Category Name -->
      <div class="w-full mb-4">
        <label for="categoryName" class="block text-sm font-medium text-gray-700">Category Name</label>
        <input v-model="categoryName" type="text" id="categoryName" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm" placeholder="Category Name">
      </div>
      <!-- Category Status -->
      <div class="w-full mb-4">
        <label for="categoryStatus" class="block text-sm font-medium text-gray-700">Category Status</label>
        <select v-model="categoryStatus" id="categoryStatus" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm">
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>
      <!-- Form Buttons -->
      <div class="flex justify-between w-full">
        <button class="py-2 px-4 bg-gray-300 text-gray-700 rounded-md" @click="cancel">Cancel</button>
        <div class="flex space-x-2">
          <button class="py-2 px-4 bg-emerald-500 text-white rounded-md" @click="save">Save</button>
          <button v-if="isEditMode" class="py-2 px-4 bg-red-500 text-white rounded-md" @click="showDeleteConfirmation = true">Delete</button>
        </div>
      </div>
    </div>
    <!-- Success Modal -->
    <div v-if="showSuccessModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" @click.self="closeSuccessModal">
      <div class="bg-white rounded-lg overflow-hidden shadow-xl max-w-lg w-full">
        <div class="p-4 border-b">
          <h3 class="text-xl font-semibold">Success!</h3>
          <button class="absolute top-4 right-4 text-gray-700" @click="closeSuccessModal">✕</button>
        </div>
        <div class="p-4 text-center">
          <p class="mb-4">Category has been {{ isEditMode ? 'updated' : 'added' }} successfully.</p>
          <button class="py-2 px-4 bg-emerald-500 text-white rounded-md" @click="closeSuccessModal">OK</button>
        </div>
      </div>
    </div>
    <!-- Delete Confirmation -->
    <div v-if="showDeleteConfirmation" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" @click.self="closeDeleteConfirmation">
      <div class="bg-white rounded-lg overflow-hidden shadow-xl max-w-lg w-full">
        <div class="p-4 border-b">
          <h3 class="text-xl font-semibold">Confirm Delete</h3>
          <button class="absolute top-4 right-4 text-gray-700" @click="closeDeleteConfirmation">✕</button>
        </div>
        <div class="p-4 text-center">
          <p class="mb-4">Are you sure you want to delete this category?</p>
          <div class="flex justify-center space-x-4">
            <button class="py-2 px-4 bg-gray-300 text-gray-700 rounded-md" @click="closeDeleteConfirmation">Cancel</button>
            <button class="py-2 px-4 bg-red-500 text-white rounded-md" @click="remove">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../api/axios';

export default {
  props: {
    categoryId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      categoryName: '',
      categoryStatus: 'true', // Default status to active
      icon: null,
      previewImage: null,
      showSuccessModal: false,
      showDeleteConfirmation: false,
      isEditMode: false
    };
  },
  watch: {
    categoryId: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          this.isEditMode = true;
          await this.loadCategory(newVal);
        } else {
          this.isEditMode = false;
          this.categoryName = '';
          this.categoryStatus = 'true';
          this.icon = null;
          this.previewImage = null;
        }
      }
    }
  },
  methods: {
    async loadCategory(id) {
      try {
        const response = await axios.get(`/v1/web/categories/${id}`);
        if (response.data && response.data.data) {
          const category = response.data.data;
          const apiBaseUrl = process.env.VUE_APP_API_BASE_URL || 'https://api.go-bary.de';
          this.previewImage = `${apiBaseUrl}/api/uploads/${category.icon}`;
          this.categoryName = category.name;
          this.categoryStatus = category.status;
        }
      } catch (error) {
        console.error('Error loading category:', error.response ? error.response.data : error.message);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      this.icon = event.target.files[0];
      this.previewImage = URL.createObjectURL(this.icon);
    },
    async save() {
      if (!this.categoryName) {
        alert('Please enter a category name.');
        return;
      }

      const formData = new FormData();
      formData.append('name', this.categoryName);
      formData.append('status', this.categoryStatus);
      if (this.icon) {
        formData.append('icon', this.icon);
      }

      try {
        let response;
        console.log('Saving category:', this.isEditMode ? 'Updating' : 'Creating', formData);
        if (this.isEditMode) {
          response = await axios.post(`/v1/web/categories/${this.categoryId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        } else {
          response = await axios.post('/v1/web/categories', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        }

        if (response.data.status) {
          this.showSuccessModal = true;
          this.$emit('category-saved', response.data.data);
        } else {
          alert(response.data.msg || `Failed to ${this.isEditMode ? 'update' : 'add'} category.`);
        }
      } catch (error) {
        console.error(`Error ${this.isEditMode ? 'updating' : 'adding'} category:`, error.response ? error.response.data : error.message);
        alert(`An error occurred while ${this.isEditMode ? 'updating' : 'adding'} the category: ${error.response ? error.response.data.message : error.message}`);
      }
    },
    async remove() {
      try {
        const response = await axios.delete(`/v1/web/categories/${this.categoryId}`);

        if (response.data.status) {
          this.showDeleteConfirmation = false;
          this.showSuccessModal = true;
          this.$emit('category-deleted', this.categoryId);
        } else {
          alert(response.data.msg || 'Failed to delete category.');
        }
      } catch (error) {
        console.error('Error deleting category:', error.response ? error.response.data : error.message);
        alert('An error occurred while deleting the category.');
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    closeSuccessModal() {
      this.showSuccessModal = false;
      this.$emit('close-success');
      this.$emit('cancel');
    },
    closeDeleteConfirmation() {
      this.showDeleteConfirmation = false;
    }
  }
};
</script>
