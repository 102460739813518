<template>
    <div class="modal-overlay" @click.self="closeModal">
      <div class="modal">
        <button class="close-button" @click="closeModal">&times;</button>
        <h2>Add an Admin</h2>
        <div class="form-group">
          <input type="text" v-model="newAdmin.name" placeholder="Name" />
        </div>
        <div class="form-group">
          <input type="text" v-model="newAdmin.username" placeholder="Username" />
        </div>
        <div class="form-group">
          <input type="password" v-model="newAdmin.password" placeholder="Password" />
        </div>
        <button class="add-button" @click="addAdmin">Add</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      show: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        newAdmin: {
          name: '',
          username: '',
          password: '',
        },
      };
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      async addAdmin() {
        try {
          const response = await this.$emit('add-admin', this.newAdmin);
          if (response && response.status) {
            this.newAdmin = { name: '', username: '', password: '' };
            this.closeModal();
          } else {
            alert('Failed to add admin.');
          }
        } catch (error) {
          console.error('Error adding admin:', error);
          alert('An error occurred. Please try again later.');
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .form-group {
    margin: 10px 0;
  }
  
  input[type='text'],
  input[type='password'] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .add-button {
    background-color: #37b883;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-button:hover {
    background-color: #6ce6df;
  }
  </style>
  