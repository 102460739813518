import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from './api/axios';
import './assets/CSS/tailwind.css';
import './assets/styles/main.css';

const app = createApp(App);

app.use(router);
app.config.globalProperties.$axios = axios;

app.mount('#app');