import { createRouter, createWebHistory } from "vue-router";

import DashboardPage from "@/pages/master/DashboardPage.vue";
import HomeTerminal from '../components/HomeTerminal'
import BuyersTerminal from '../components/BuyersTerminal'
import SellersTerminal from '../components/SellersTerminal'
import CategoriesTerminal from '../components/CategoriesTerminal'
import SystemInformation from '../components/SystemInformation'
import SettingsTerminal from '../components/SettingsTerminal'
import LoginPage from "@/components/LoginPage.vue";
import AdminTerminal from "@/components/AdminTerminal.vue";

    const routes = [
        {
            path: '/',
            redirect: '/login'
        },
        {
            name: 'LoginPage',
            path: '/login',
            component: LoginPage
        },
        {
            name: 'DashboardPage',
            path: '/DashboardPage',
            component: DashboardPage,
            children: [
                { path: 'HomeTerminal', component: HomeTerminal },
                { path: 'BuyersTerminal', component: BuyersTerminal },
                { path: 'SellersTerminal', component: SellersTerminal },
                { path: 'CategoriesTerminal', component: CategoriesTerminal },
                { path: 'SystemInformation', component: SystemInformation },
                { path: 'settingsTerminal', component: SettingsTerminal },
                { path: 'AdminTerminal', component: AdminTerminal}
              ],
            meta: { requiresAuth: true }
        },  
    ];
    const router = createRouter({
        history: createWebHistory(),
        routes,
      });
      
      router.beforeEach((to, from, next) => {
        const token = localStorage.getItem('token');
        if (to.matched.some(record => record.meta.requiresAuth) && !token) {
          next('/login');
        } else {
          next();
        }
      });
      
      export default router;