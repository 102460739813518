<template>
  <div class="login-wrapper">
    <div class="login-box">
      <h1 class="bold-text large-text">Admin Panel</h1>
      <h3>Login</h3>
      <div class="text">
        <input type="text" v-model="email" placeholder="Username" />
        <div class="password-wrapper">
          <input :type="showPassword ? 'text' : 'password'" v-model="password" placeholder="Password" />
          <button type="button" class="toggle-password" @click="togglePasswordVisibility">
            <span v-if="showPassword">Show</span>
            <span v-else></span>
          </button>
        </div>
      </div>
      <!--<div class="form-group language-select-wrapper">
        <LanguageSelect v-model="language" />
      </div>-->
      <div class="form-group">
        <button type="submit" class="btn btn-block" @click="handleLogin">Login</button>
      </div>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { login } from '../api/authService';
import LanguageSelect from './LanguageSelect.vue';

const email = ref('');
const password = ref('');
const language = ref('en'); // Default language
const showPassword = ref(false);
const router = useRouter();
const errorMessage = ref('');

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

const handleLogin = async () => {
  try {
    console.log('Attempting login with:', email.value, password.value, language.value);
    const response = await login(email.value, password.value, language.value);
    console.log('Login response:', response);
    if (response && response.status) {
      localStorage.setItem('token', response.token);
      localStorage.setItem('name', response.name); // Store user name
      localStorage.setItem('adminId', response.adminId); // Store admin ID
      router.push('/DashboardPage/HomeTerminal');
    } else {
      errorMessage.value = response ? response.msg : 'Login failed. Please check your email and password.';
    }
  } catch (error) {
    console.error('Error during login:', error.response ? error.response.data : error.message);
    errorMessage.value = 'An error occurred. Please try again later.';
  }
};
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-box {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.bold-text {
  font-weight: bold;
}

.large-text {
  font-size: 2em;
}

h1 {
  margin-bottom: 0.5em;
}

h3 {
  margin-bottom: 1em;
  color: gray;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
}

.password-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

input[type='text'],
input[type='password'],
input[type='text'] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.toggle-password {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px; /* Adjust font size if necessary */
}

.form-group {
  margin-top: 1em;
  width: 100%;
  text-align: center;
}

.language-select-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-select-wrapper label {
  margin-right: 10px;
}

select.form-control {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

select.form-control option {
  display: flex;
  align-items: center;
}

select.form-control option img.flag-icon {
  width: 20px;
  height: 14px;
  margin-right: 10px;
}

.btn {
  background-color: #37b883;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.btn:hover {
  background-color: #6ce6df;
}

.error-message {
  margin-top: 1em;
  color: red;
  font-size: 14px;
}
</style>
