// src/api/authService.js
import axios from './axios';

export const login = async (email, password, language) => {
  try {
    console.log('Sending login request with:', { email, password, language });
    const response = await axios.post(
      '/v1/web/login', // Correct endpoint path
      { email, password },
      { headers: { 'lang': language } } // Explicitly set the language header
    );
    console.log('Received response:', response);
    return response.data;
  } catch (error) {
    console.error('Login request failed:', error.response ? error.response.data : error.message);
    throw error;
  }
};
